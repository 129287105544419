import {combineReducers} from "redux";
import {Balance} from "@src/Models";
import {createStore} from "redux";

function balanceReducer(state: Balance = null, action: any): Balance {
	if(action.type == "refreshBalance") 
		return action.data;
	return state;
}

function loggedInReducer(state: boolean | null = null, action: any): boolean | null {
	if(action.type == "login") return true;
	if(action.type == "logout") {
		localStorage.removeItem("user");
		return false;	
	}
	return state;
}

function registrationDataReducer(state: any = null, action: any) {
	if(action.type == "saveRegistrationData") {
		if(action.data !== null)
			localStorage.setItem("registrationData", JSON.stringify(action.data));
		else
			localStorage.removeItem("registrationData");
	}
	let result = localStorage.getItem("registrationData");
	if(result === null) return null;
	return JSON.parse(result);
}

function popupMessagesReducer(state: JSX.Element[] = [], action: any) {
	if(action.type == "pushMessage")
		return [...state, action.data];
	if(action.type == "popMessage" && state.length >= 1)
		return [...state.slice(1)];
	return state;	
}

export const appReducer = combineReducers({
	balance: balanceReducer,
	loggedIn: loggedInReducer,
	registrationData: registrationDataReducer,
	popupMessages: popupMessagesReducer 
})

export const store = createStore(appReducer);

export type RootState = ReturnType<typeof appReducer>;