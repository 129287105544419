import * as React from "react";
import { w3cwebsocket } from "websocket";
import * as apiClient from "@src/apiClient";
import QRCode from "qrcode.react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@src/Reducers";
import { refreshBalance, login, logout, saveRegistrationData, pushMessage, popMessage } from "@src/Actions";
import { Balance, Transaction, QRData } from "@src/Models";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "@src/app.scss";
import ReactCardFlip from 'react-card-flip';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as dateFns from "date-fns";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Formik, Field, Form, FieldArray } from 'formik';

const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'JPY',
});

export function Icon(props: {
	name: string;
}) {
	return <img className="icon" src={"/ico/" + props.name + ".svg"} alt={props.name} />;
}

function timer(s: number) {
	return dateFns.format(s * 1000, "mm:ss");
}

function QRCodePanel(props: {
	data: QRData;
	chargeAmount: number;
	payAmount: number;
	onValidated: (transactions: Transaction[]) => void;
	onDismiss: () => void;
}) {
	const [connected, setConnected] = React.useState<boolean>(false);
	const [currentTime, setCurrentTime] = React.useState<number>(Math.ceil(new Date().getTime() / 1000));
	const dispatch = useDispatch();
	React.useEffect(() => {
		if (props.data != null) {
			const client = new w3cwebsocket("wss://" + location.host + "/ws");
			client.onopen = () => {
				client.send(JSON.stringify({
					qrcode: props.data.transactionId,
					accessToken: props.data.wsToken
				}));
				setConnected(true);
			}
			client.onmessage = message => {
				props.onValidated(JSON.parse(message.data.toString(), (key, value) => {
					if (key == "date") return dateFns.parse(value, "yyyy/MM/dd HH:mm:ss", 0);
					return value;
				}));
			};
			client.onclose = () => {
				props.onDismiss();
			};
			client.onerror = () => {
				dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Error connecting server</p></div>));
			}
			return () => client.close();
		}
	}, [props.data]);
/*	React.useEffect(() => {
		const timer = setInterval(() => setCurrentTime(Math.ceil(new Date().getTime() / 1000)), 1000);
		return () => clearInterval(timer);
	}, []);*/
	React.useEffect(() => {
		if(props.data != null && Math.floor(props.data.expires.getTime() / 1000) - currentTime <= 0) props.onDismiss();
	}, [currentTime]);
	if (props.data == null || !connected) return null;
	return <div className="qrcode-panel">
		<div className="code"><QRCode value={props.data.transactionId} renderAs="svg" style={{height: "35mm", width: "35mm", border: "2mm solid white", background: "white"}} /><span className="timer">{/*timer(Math.floor(props.data.expires.getTime() / 1000) - currentTime)*/}</span></div>
		<div className="details">
			{props.chargeAmount > 0 ? <p className="charge">Charge <span className="amount">{currencyFormatter.format(props.chargeAmount)}</span></p> : null}
			{props.payAmount > 0 ? <p className="pay">Pay <span className="amount">{currencyFormatter.format(props.payAmount)}</span></p> : null}
			<button onClick={props.onDismiss}>Cancel</button>
		</div>
	</div>;
}

function Balance() {
	const balance = useSelector<RootState, Balance>((state: RootState) => state.balance);
	const [isFlipped, setFlipped] = React.useState<boolean>(false);
	return <section className="balance" onClick={() => setFlipped(!isFlipped)}>
		<ReactCardFlip isFlipped={isFlipped}>
			<div className="front">
				<h1>Balance</h1>
				<span className="amount">{balance == null ? "-" : currencyFormatter.format(balance.cash + balance.points)}</span>
			</div>
			<div className="back">
				Cash: <span className="amount">{balance == null ? "-" : currencyFormatter.format(balance.cash)}</span><br />
	Points: <span className="amount">{balance == null ? "-" : currencyFormatter.format(balance.points)}</span>
			</div>
		</ReactCardFlip>
		<img src="ico/shuffle.svg" className="shuffle" alt="shuffle" />
	</section>;
}
function ExecuteButton(props: {
	onClick: () => void;
	chargeAmount: number;
	payAmount: number;
}) {
	const balance = useSelector<RootState, Balance>((state: RootState) => state.balance);
	if ((props.payAmount == 0 && props.chargeAmount == 0) || balance == null) return null;
	if (props.payAmount < 0 || props.chargeAmount < 0 || Number.isNaN(props.payAmount) || Number.isNaN(props.chargeAmount)) return null;
	if (balance.cash + balance.points + props.chargeAmount < props.payAmount) return <span className="error">Please charge</span>;
	return <button onClick={props.onClick}>
		{props.chargeAmount > 0 ? "Charge " + currencyFormatter.format(props.chargeAmount) : ""}
		{props.payAmount > 0 && props.chargeAmount > 0 && <br />}
		{props.payAmount > 0 ? "Pay " + currencyFormatter.format(props.payAmount) : ""}
	</button>;
}

function EventToast(props: {
	type: string;
	sound: string;
	amount: number;
}) {
	let message = null;
	let sound = null;
	if(props.type == "pay") message = <><Icon name="success" /><p>Payment succeeded: <span className="amount">{currencyFormatter.format(props.amount)}</span></p></>;
	if(props.type == "charge") message = <><Icon name="success" /><p>Charge succeeded: <span className="amount">{currencyFormatter.format(props.amount)}</span></p></>;
	if(props.type == "awards") message = <><Icon name="success" /><p>Points awarded: <span className="amount">{currencyFormatter.format(props.amount)}</span></p></>;
	if(props.type == "otsumami") message = <><Icon name="lucky" /><p>You won FREE otsumami!<br />Please claim your prize to the barman!!!</p></>;
	if(props.sound == "jean2") sound = <audio autoPlay={true} loop={false} controls={false} src="/jean2.mp3" />;
	if(props.sound == "otsumami") sound = <audio autoPlay={true} loop={false} controls={false} src="/otsumami.mp3" />;
	return <div className="message">{message}{sound}</div>;
}

export function Main() {
	const [payAmount, setPayAmount] = React.useState<number>(null);
	const [chargeAmount, setChargeAmount] = React.useState<number>(null);
	const [qrcode, setQrcode] = React.useState<QRData>(null);
	const [selectedTab, setSelectedTab] = React.useState<number>(0)
	const dispatch = useDispatch();
	React.useEffect(() => {
		apiClient.getBalance().then(balance => dispatch(refreshBalance(balance)));
	}, []);
	function onDismiss() {
		setQrcode(null);
		setPayAmount(0);
		setChargeAmount(0);
		setSelectedTab(0);
	}
	function onValidated(transactions: Transaction[]) {
		onDismiss();
		let soundPlayed = false;
		for(let transaction of transactions) {
			if(transaction.description == "otsumami") {
				dispatch(pushMessage(<EventToast type="otsumami" amount={0} sound={soundPlayed ? null : "otsumami"} />));
				soundPlayed = true;
				continue;
			}
			if (transaction.cash < 0 || transaction.points < 0) {
				dispatch(pushMessage(<EventToast type="pay" amount={-transaction.cash - transaction.points} sound={soundPlayed ? null : "jean2"} />));
				soundPlayed = true;
			}
			if (transaction.cash > 0)
				dispatch(pushMessage(<EventToast type="charge" amount={transaction.cash} sound={null} />));
			if (transaction.points > 0)
				dispatch(pushMessage(<EventToast type="awards" amount={transaction.points} sound={null} />));
		};
		apiClient.getBalance().then(balance => dispatch(refreshBalance(balance)));
		if (window.navigator && window.navigator.vibrate) window.navigator.vibrate(200);
	}
	function generateCode() {
		setQrcode(null);
		apiClient.getQrData(payAmount, chargeAmount).then(setQrcode);
	}
	return <>
		<Balance />
		<Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
			<TabList className="react-tabs__tab-list tab-list">
				<Tab className="react-tabs__tab tab" selectedClassName="selected-tab">Pay</Tab>
				<Tab className="react-tabs__tab tab" selectedClassName="selected-tab">Charge</Tab>
			</TabList>
			<TabPanel className="react-tabs__tab-panel tab-content">
				Amount to pay:
				<input type="number" className="amount pay" placeholder="0" value={payAmount || ""} onChange={e => setPayAmount(e.target.valueAsNumber)} /><br />
				<button className="amount" onClick={() => setPayAmount(400)}>{currencyFormatter.format(400)}</button>
				<button className="amount" onClick={() => setPayAmount(450)}>{currencyFormatter.format(450)}</button>
				<button className="amount" onClick={() => setPayAmount(550)}>{currencyFormatter.format(550)}</button>
				<button className="amount" onClick={() => setPayAmount(600)}>{currencyFormatter.format(600)}</button>
				<button className="amount" onClick={() => setPayAmount(700)}>{currencyFormatter.format(700)}</button>
				<button className="amount" onClick={() => setPayAmount(800)}>{currencyFormatter.format(800)}</button>
			</TabPanel>
			<TabPanel className="react-tabs__tab-panel tab-content">
				Amount to charge:
				<input type="number" className="amount charge" placeholder="0" value={chargeAmount || ""} onChange={e => setChargeAmount(e.target.valueAsNumber)} /><br />
				<button className="amount" onClick={() => setChargeAmount(5000)}>{currencyFormatter.format(5000)}</button>
				<button className="amount" onClick={() => setChargeAmount(10000)}>{currencyFormatter.format(10000)}</button>
				<button className="amount" onClick={() => setChargeAmount(20000)}>{currencyFormatter.format(20000)}</button>
			</TabPanel>
		</Tabs>
		<footer>
			<ExecuteButton onClick={generateCode} payAmount={payAmount || 0} chargeAmount={chargeAmount || 0} />
		</footer>
		<QRCodePanel data={qrcode} onValidated={onValidated} onDismiss={onDismiss} payAmount={payAmount || 0} chargeAmount={chargeAmount || 0} />
	</>;
}

function Login() {
	const dispatch = useDispatch();
	dispatch(saveRegistrationData(null));
	return <div className="login">
		<h1>Osaka Salon Pay</h1>
		<p>To create a new account, <Link to="/register">click here</Link></p>
		<p>If you already have an account, login with</p>
		<ul className="loginChoice">
		<li><Link to="/login?authclient=facebook"><img src="/ico/facebook.svg" alt="Facebook" /></Link></li>
		<li><Link to="/login?authclient=line"><img src="/ico/line.svg" alt="Line" /></Link></li>
		<li><Link to="/login?authclient=google"><img src="/ico/google.svg" alt="Google" /></Link></li>
		</ul>
	</div>;
}

function SSO() {
	let urlParams = new URLSearchParams(window.location.search);
	const history = useHistory();
	const dispatch = useDispatch();
	if (urlParams.has("authclient"))
		apiClient.getAuthUrl(urlParams.get("authclient"),
		new URL("../authenticate?authclient=" + urlParams.get("authclient"), document.baseURI).href)
		.then(url => window.location.replace(url))
		.catch(() => {
			dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>SSO url not found</p></div>));
			history.push("/");
		});
	return <section className="authentication"><p>Please wait while we check your identity</p></section>;
}

function Authenticate() {
	const dispatch = useDispatch();
	const loggedIn = useSelector<RootState, boolean | null>(state => state.loggedIn);
	const history = useHistory();
	var registrationData = useSelector<RootState, any>(state => state.registrationData);
	React.useEffect(() => {
		apiClient.sendAuthCode()
			.then(token => {
				if(!loggedIn) {
					if(registrationData === null) {
						localStorage.setItem("user", token.accessToken);
						dispatch(login());
						history.push("/");
					} else {
						if(token.accessToken != null) {
							dispatch(saveRegistrationData(null));
							dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>You account already exists, please login with it</p></div>));
							history.push("/");
						} else {
							let jwt = JSON.parse(atob(token.ssoToken.split(".")[1]));
							if(registrationData.logins == null) registrationData.logins = [];
							dispatch(saveRegistrationData({	...jwt, ...registrationData, logins: [...registrationData.logins, token.ssoToken]}));
							history.push("/register");
						}
					}
				} else {
					
				}
			})
			.catch(() => {
				dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Could not authenticate</p></div>));
				history.push("/");
			});
	}, []);
	return <section className="authentication"><p>Please wait while we check your identity</p></section>;
}

function Terms(props: {
	referrer: string
}) {
	return <section className="terms">
	<h1>Terms and conditions</h1>
	<ul>
	<li>Osaka Salon Pay is provided as a payment facility and fidelization means for Osaka Salon customers</li>
	<li>Osaka Salon Pay will receive personal information (name) but shall only use it to identify customers with their balance. That information won't be transmitted to any third party.</li>
	<li>The balance may be used for drinks, events and language lessons at Osaka Salon</li>
	<li>Additional credits may be provided on balance top up, but will be used only when the cash balance is used up and won't be redeemable for cash</li>
	<li>Osaka Salon Pay is provided as is without any warranty. Osaka Salon shall not be liable for any loss or overdrinking :)</li>
	<li>To delete your account, including all the data associated to it, and redeem your cash, please contact directly the staff or send a mail to <a href="mailto:support@osakasalon.com">support@osakasalon.com</a></li>
	<li>These terms and conditions may be revised in the future</li>
	</ul>
	<Link to={props.referrer}>OK</Link>
	</section>	
}

function Header() {
	return <header>
	<Link to="/"><h1>Osaka Salon Pay</h1></Link>
	<Menu />
	</header>
}

function Menu() {
	const loggedIn = useSelector<RootState, boolean | null>(state => state.loggedIn);
	const [toggled, setToggled] = React.useState(false);
	if(loggedIn == null) return null;
	return <nav>
	<div className={"hamburger" + (toggled ? " toggled": "")} onClick={() => setToggled(!toggled)}><span></span><span></span><span></span></div>
	<ul onClick={() => setToggled(false)}>
	<li><Link to="/profile">Profile</Link></li>
	<li><Link to="/history">History</Link></li>
	<li><Link to="/logout">Logout</Link></li>
	<li><Link to="/terms">Terms</Link></li>
	</ul>
	</nav>;
}

function Profile() {
	const [profile, setProfile] = React.useState<any>(null);
	const history = useHistory();
	React.useEffect(() => {apiClient.getProfile().then(setProfile)}, []);
	if(profile == null) return null;
	function formatAccountNo(s: string) {
		return s.substr(0, 4) + "-" + s.substr(4, 4) + "-" + s.substr(8, 4) + "-" + s.substr(12, 4); 
	}
	return <section className="profile">
	<Formik
	initialValues={profile}
	onSubmit={(values, { setSubmitting }) => {
		apiClient.saveProfile(values).then(() => {
			setSubmitting(false);
			history.push("/");
		});
   }}><Form>
	<div className="field">
	<label>Account number</label>
	<span className="accountNo">{formatAccountNo("" + profile.accountNo)}</span>
	</div>
	<div className="field">
	<label htmlFor="name">Name</label>
	<Field name="name" type="text" />
	</div>
	<button type="submit">OK</button>
	</Form></Formik>

	</section>;
}

function HistoryItem(props: {
	transaction: any
}) {
	if(props.transaction.description == "otsumami") return <li>
		<div className="date">{dateFns.format(props.transaction.date, "yyyy/MM/dd HH:mm:ss")}</div>
		<div><span className="operation">Otsumami service!!!</span></div>
	</li>;
	return <li>
		<div className="date">{dateFns.format(props.transaction.date, "yyyy/MM/dd HH:mm:ss")}</div>
		{props.transaction.cash > 0 && <div><span className="operation">Charge</span><span className="amount charge">{currencyFormatter.format(props.transaction.cash)}</span></div>}
		{props.transaction.points > 0 && <div><span className="operation">Points awarded</span><span className="amount charge">{currencyFormatter.format(props.transaction.points)}</span></div>}
		{props.transaction.cash <= 0 && <div><span className="operation">Payment</span><span className="amount">{currencyFormatter.format(-props.transaction.cash-props.transaction.points)}</span></div>}
		{props.transaction.points < 0 && <div><span className="operation">Including points used</span><span className="amount">{currencyFormatter.format(-props.transaction.points)}</span></div>}
	</li>
}

function History() {
	const [transactions, setTransactions] = React.useState<Transaction[]>(null);
	const [hasMore, setHasMore] = React.useState<boolean>(true);
	const pageSize = 25;
	function fetchData() {
		apiClient.getTransactions(transactions == null ? 0 : transactions.length, pageSize).
		then(fetchedTransactions => {
			if(fetchedTransactions.length == 0) setHasMore(false);
			if(transactions == null)
				setTransactions([...fetchedTransactions]);
			else
				setTransactions([...transactions, ...fetchedTransactions]);
		});
	}
	React.useEffect(() => { fetchData(); }, []);
	if(transactions == null) return null;
	return <section className="history">
		<InfiniteScroll
			dataLength={transactions.length}
			next={fetchData}
			hasMore={hasMore}
			loader="Loading..."
			endMessage={transactions.length != 0 && <>That's all folks!</>}>
			{transactions.length == 0 && <>Nothing yet. Drink!!!</>}
			{transactions.length > 0 && <ul>
				{transactions.map(transaction => <HistoryItem key={transaction.id} transaction={transaction} />)}
			</ul>}
		</InfiniteScroll>
	</section>;
}

function Logout() {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(logout())
	}, []);	
	return null;
}

function Registration() {
	const registrationData = useSelector<RootState, any>(state => state.registrationData);
	const history = useHistory();
	const dispatch = useDispatch();
	function initiateRegistration(client: string) {
		dispatch(saveRegistrationData({}));
		history.push("/login?authclient=" + client);
	}
	if(registrationData == null || registrationData.logins == null) {
		return <section className="registration">
		<h1>Registration</h1>
		<p>
		By registering, you accept our <Link to="/terms">terms and conditions</Link>
		</p>
		<p>Create your account with</p>
		<ul className="loginChoice">
		<li><img src="/ico/facebook.svg" alt="Facebook" onClick={() => initiateRegistration("facebook")} /></li>
		<li><img src="/ico/line.svg" alt="Line" onClick={() => initiateRegistration("line")} /></li>
		<li><img src="/ico/google.svg" alt="Google" onClick={() => initiateRegistration("google")} /></li>
		</ul>
		<Link to="/">Go back</Link>
		</section>;
	} else {
		return <section className="profile">
		<h1>Registration</h1>
		<Formik
			initialValues={registrationData}
			onSubmit={(values, { setSubmitting }) => {
				apiClient.registerUser(values).then(token => {
					setSubmitting(false);
					dispatch(saveRegistrationData(null));
					localStorage.setItem("user", token.accessToken);
					dispatch(login());
				})
				.catch(() => {
					dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Could not authenticate</p></div>));
					dispatch(saveRegistrationData(null));
				});
	       }}><Form>
			<div className="field">
			<label htmlFor="name">Name</label>
			<Field name="name" type="text" />
			<FieldArray name="logins" render={() =>
				registrationData.logins.map((login, index) => <Field name={`logins.${index}`} key={index} type="hidden" />)
			} />
			</div>
			<button type="submit">OK</button>
			<Link to="/">Go back</Link>
		</Form></Formik>
		</section>;
	}
}

function Popup() {
	const messages = useSelector<RootState, JSX.Element[]>(state => state.popupMessages);
	const dispatch = useDispatch();
	function dismiss() {
		dispatch(popMessage());
	}
	if(messages.length == 0) return null;
	return <div className="overlay"><div className="body">
	{messages[0]}
	<div className="actionButtons"><button onClick={dismiss}>OK</button></div>
	</div></div>;
}

export function App() {
	const loggedIn = useSelector<RootState, boolean | null>(state => state.loggedIn);
	const dispatch = useDispatch();
	React.useEffect(() => {
		if(apiClient.isLoggedIn())
			dispatch(login());
		else
			dispatch(logout());
	}, []);
	if(loggedIn === null) return null;
	return	<><Router>
		{!loggedIn && <Switch>
		<Route path="/login"><SSO /></Route>
		<Route path="/authenticate"><Authenticate /></Route>
		<Route path="/register"><Registration /></Route>
		<Route path="/terms"><Terms referrer="/register" /></Route>
		<Route path="/" exact={true}><Login /></Route>
		<Route><Redirect to="/" /></Route>
		</Switch>}
		{loggedIn && <>
		<Header />
		<Switch>
		<Route path="/terms"><Terms referrer="/" /></Route>
		<Route path="/history"><History /></Route>
		<Route path="/profile"><Profile /></Route>
		<Route path="/logout"><Logout /></Route>
		<Route path="/" exact={true}><Main /></Route>
		<Route><Redirect to="/" /></Route>
		</Switch></>}
	</Router><Popup /></>;
}
