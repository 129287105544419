import {Balance} from "@src/Models";
export function refreshBalance(balance: Balance) {
	return {
		type: "refreshBalance",
		data: balance
	}
}

export function login() {
	return { type: "login" };
}

export function logout() {
	return { type: "logout" };
}

export function saveRegistrationData(data: any) {
	return {
		type: "saveRegistrationData",
		data: data
	};
}

export function pushMessage(message: JSX.Element) {
	return {
		type: "pushMessage",
		data: message
	}
}

export function popMessage() {
	return {
		type: "popMessage"
	}
}