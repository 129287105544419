import * as React from "react";
import * as ReactDOM from "react-dom";
import {App} from "@src/App";
import {Provider} from "react-redux";
import {store} from "@src/Reducers";

async function installServiceWorker() {
	if('serviceWorker' in navigator) { 
		try {
			await navigator.serviceWorker.register('/service-worker.js');
		} catch (e) {
			console.log('ServiceWorker registration failed. Sorry about that.');
		}
	} else {
		console.log('No service worker found');
	}
}

installServiceWorker();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("app"));